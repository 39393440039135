import axios from "axios";
import * as types from "./types";
import { REQUEST_URL } from "../constant/Constant";

export const getContent = (type) => async (dispatch) => {
  dispatch({
    type: types.CONTENT_REQUEST_START,
  });

  try {
    const res = await axios.get(
      `${REQUEST_URL}/api/competitions/content/${type}/`
    );
    if (type === "FAQ") {
      dispatch({
        type: types.GET_CONTENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    if (type === "FAQ") {
      dispatch({
        type: types.GET_CONTENT_FAIL,
      });
    }
  }
};
