import React,{Component} from "react";
import "./terms-conditions.css";
import Navbar from "../Navbar/navbar.jsx";
import Footer from "../Footer/footer";

class TermsConditions extends React.Component {
  render() {
    return (
      <>
        <Navbar />
        <section class="terms" id="terms-title" style={{display:"flex",justifyContent:"center", flexDirection:"column"}}>
        <h4 class="mt-lg-4">Termeni și Condiții</h4>

          <div>
            {/* <h4>Privacy Policy</h4>
            <p>Lorem ipsum dolores</p>
            <p>A-Z letters-567 entries per letter</p> */}

            <p>
              Bine ați venit la Paradise Competitions, locul definitiv al
              concursurilor impresionante, luxului și cadourilor. Sperăm că vă
              veți bucura de ceea ce are de oferit site-ul nostru. Ne străduim
              să ne asigurăm că clienții noștri beneficiază de o experiență de
              cumpărături fără efort.
            </p>

            <p>
              Prin utilizarea oricăreia dintre serviciile noastre, chiar și doar
              navigând pe site-urile noastre, sunteți de acord cu acești
              Termeni. Dacă nu sunteți de acord cu aceștia, nu puteți utiliza
              serviciile noastre. Ești cu noi? Excelent, continuă să citești!
            </p>
            <p>
              <strong>
                <ul>
                  <li>• Acceptarea acestor Termeni</li>
                  <li>• Reclamații</li>
                  <li>• Livrare Rapidă prin Curier</li>
                  <li>• Anulare</li>
                  <li>• Returnări Fără Întrebări și Rambursare în 24 de Ore</li>
                  <li>• Condiții Generale Aplicabile Returnărilor</li>
                  <li>• Plată</li>
                  <li>• Prețuri</li>
                  <li>• Disponibilitatea Produselor</li>
                  <li>• Înregistrare</li>
                  <li>• Comenzi</li>
                  <li>• Descrieri</li>
                  <li>• Activități de Stimulare a Vânzărilor</li>
                  <li>• Angajament Legal</li>
                  <li>• Participarea la un Concurs Online</li>
                  <li>• Închiderea unui Concurs</li>
                  <li>• Judecata Concursului</li>
                  <li>• Câștigători</li>
                  <li>• Premii</li>
                  <li>• Depozitare Premii</li>
                  <li>• Limite de Răspundere</li>
                  <li>• Notificare de Protecție a Datelor</li>
                  <li>• Participarea la un Concurs prin Poștă</li>
                  <li>• Utilizare Inadecvată</li>
                  <li>• Despăgubiri</li>
                  <li>• Cesiunea Drepturilor și Obligațiilor</li>
                  <li>• Drepturi de Proprietate Intelectuală</li>
                  <li>• Acordul Integral</li>
                </ul>
              </strong>
            </p>
            <p>
              Site-urile www.paradisecompetitions.ro si
              www.paradisecompetitions.com si aplicațiile mobile Paradise
              Competitions (împreună, "Site-ul nostru") sunt deținute și operate
              de Glam Infostyle, care face comerț sub numele Paradise
              Competitions în beneficiul său și al filialelor și subsidiarelor
              sale din Europa.
            </p>
            <p>
              Glam Infostyle ("Paradise Competitions", "noi", "ne", "noastre")
              este o companie cu răspundere limitată înregistrată în Uniunea
              Europeană, cu număr de licență comercială RO 36488183 și sediul în
              str. Pajurei nr.13, Sector 1, 013152 Bucuresti, Romania.
            </p>
            <p>
              <strong>Acceptarea acestor Termeni</strong>
            </p>
            <p>
              Vă rugăm să citiți acești termeni și condiții și orice alte
              termeni de utilizare publicați pe Site și incluși în orice
              material promoțional aferent, deoarece împreună formează
              "Termenii" noștri care guvernează utilizarea site-ului și a
              "Serviciilor" noastre (inclusiv serviciile site-ului, serviciile
              pentru clienți și alte activități desfășurate de noi sub denumirea
              comercială "Paradise Competitions").
            </p>
            <p>
              Putem modifica acești Termeni din când în când fără să vă anunțăm,
              prin postarea Termenilor actualizați pe Site, așa că vă rugăm să
              verificați în mod regulat actualizările.
            </p>
            <p>
              Când plasați o Comandă, se considera că ați consimțit la cea mai
              recentă versiune a Termenilor de pe site-ul nostru. Dacă o
              modificare a legii înseamnă că trebuie să modificăm Termenii
              noștri după ce ați plasat o Comandă, dar înainte de a fi
              îndeplinită, suntem obligați să aplicăm acea modificare Comenzii
              dumneavoastră. În toate celelalte cazuri, versiunea postată pe
              site la momentul plasării Comenzii dumneavoastră va fi aplicabilă.
            </p>
            <p>
              {" "}
              Acești Termeni au fost modificați ultima oară la 22/02/2024.{" "}
            </p>
            <p>
              Dacă nu sunteți de acord să fiți obligat de Termeni, nu ar trebui
              să utilizați Serviciile noastre.
            </p>

            <p>
              <strong>Reclamații</strong>
            </p>
            <p>
              Angajamentul nostru este total! Dacă nu reușim sau amânăm să punem
              în aplicare o prevedere a acestor Termeni, acest lucru nu este o
              renunțare la dreptul nostru de a face acest lucru mai târziu.
            </p>
            <p>
              Dacă aveți o plângere despre noi, puteți lua legătura cu echipa
              noastră de asistență pentru clienți prin intermediul adresei
              shop@paradisecompetitions.ro cu o scurtă descriere a plângerii
              dumneavoastră, numele, adresa de mail, numărul de telefon și, dacă
              există, numărul de referință al comenzii dumneavoastră.
            </p>
            <p>
              În unele cutii poștale intră în Spam e-mailurile de la
              shop@paradisecompetitions. Pentru a preveni acest lucru,
              verificați folderul Spam și etichetați e-mailul nostru ca Not
              Spam.
            </p>
            <p>
              Dacă există o dispută pe care nu am reușit să o rezolvăm direct cu
              dumneavoastră, rezidenții UE pot găsi mai multe informații despre
              soluționarea alternativă a litigiilor prin European Online Dispute
              Resolution la adresa https://ec.europa.eu/customers/odr. Pentru
              mai multe informații, vă rugăm să contactați echipa noastră de
              asistență pentru clienți.
            </p>
            <p>
              <strong>Livrare Rapidă prin Curier</strong>
            </p>
            <p>
              Metodele de livrare rapidă disponibile în prezent sunt Fan Curier
              și UPS. Cu toate acestea, ne străduim în mod constant să
              îmbunătățim experiența clienților: putem introduce metode
              suplimentare de livrare.
            </p>
            <p>
              Vă rugăm să vă asigurați că adresa de livrare pe care ne-o
              furnizați este corectă. Vă rugăm să rețineți că ne propunem să
              expediem toate Comenzile în termen de 24 de ore. Vă rugăm să luați
              în considerare că, după plasarea și plata unei comenzi, timpul de
              livrare este în medie de 7 zile lucrătoare. Nu răspundem pentru
              nicio întârziere neprevăzută. Daca va fi cazul, vă vom contacta
              cât mai curând posibil pentru a vă anunța și vom lua măsuri pentru
              a minimaliza efectul întârzierii.
            </p>
            <p>
              Veți deveni proprietarul bunurilor atunci când acestea vă vor fi
              livrate. Bunurile sunt responsabilitatea dumneavoastra începând cu
              momentul livrării acestora la adresa pe care ne-ați furnizat-o. În
              majoritatea livrărilor, solicităm o semnătură, moment în care
              responsabilitatea pentru bunurile achiziționate trece la
              dumneavoastră. Termenii curierului sunt aplicabil
            </p>
            <p>
              <strong>Anulare</strong>
            </p>
            <p>
              După ce v-ați făcut alegerea și comanda a fost plasată, veți primi
              un e-mail de confirmare a detaliilor comenzii. Acest e-mail NU
              reprezintă acceptarea comenzii dumneavoastră, ci doar o confirmare
              că am primit-o. Ne rezervăm dreptul de a nu accepta comanda
              dumneavoastră în cazul în care, de exemplu, se aplică restricții
              de livrare pentru un anumit produs, produsul comandat nu este pe
              stoc, sau nu satisface standardele noastre de control al calității
              și este retras. Cu toate eforturile depuse pentru a ne asigura că
              detaliile de pe site-ul nostru sunt precise, putem descoperi din
              când în când o eroare în prețul produselor. Nu suntem obligați să
              acceptăm sau să îndeplinim o comandă pentru un produs care a fost
              promovat la un preț incorect și ne rezervăm dreptul de a anula o
              astfel de comandă care a fost acceptată sau este în tranzit. Dacă
              ați comandat un produs la un preț incorect din orice motiv, vă vom
              anunța cât mai curând posibil că nu am acceptat comanda
              dumneavoastră și/sau că partea relevantă a comenzii dumneavoastră
              a fost anulată. Dacă ați plătit deja pentru bunuri, vom RAMBURSA
              suma integrală în cel mai scurt timp posibil. În cazul în care
              produsele sunt rechemate în tranzit, vom procesa rambursarea
              dumneavoastră după ce produsele ne-au fost returnate.
            </p>
            <p>
              În cazul în care ați cumpărat un produs digital de la noi (adică
              împreună cu o intrare la concurs), e-mailul de confirmare a
              detaliilor comenzii ESTE acceptarea comenzii dumneavoastră și
              comanda dumneavoastră nu va fi anulată niciodată.
            </p>
            <p>
              <strong>
                Returnări fără întrebări și rambursare în 24 de ore
              </strong>
            </p>
            <p>
              <strong>Pasul 1.</strong> Luați legătura cu echipa noastră de
              asistență pentru clienți prin intermediul adresei
              shop@paradisecompetitions.ro pentru o cerere de returnare,
              indicați ce element(e) doriți să returnați și motivul.
              <br />
              <strong>Pasul 2.</strong> Introduceți formularul de Schimb și
              Returnare împreună cu elementul(e) pe care doriți să le returnați
              în ambalajul original.
              <br />
              <strong>Pasul 3.</strong> Utilizați același serviciu de livrare cu
              cel folosit pentru comanda dumneavoastră (curier). Trimiteți la
              adresa de returnare pe care o veți primi de la echipa noastră de
              asistență pentru clienți.
              <br />
              <strong>Pasul 4.</strong> Odată ce am primit coletul, vom confirma
              primirea.
              <br />
              <strong>Pasul 5.</strong> Toate returnările aprobate vor fi
              creditate în termen de 24 de ore folosind moneda, contul și metoda
              de plată utilizate pentru achiziția inițială.
              <br />
              În conformitate cu condițiile prevăzute în această secțiune
              Returnări, oferim o politică de returnare gratuită "fără
              întrebări" care vă permite să returnați articolele livrate către
              noi din orice motiv până la 14 zile de la livrarea Comenzii
              dumneavoastră, fără niciun cost suplimentar.
              <br />
              Vom emite o rambursare a valorii integrale a articolelor
              nedeteriorate returnate în mod corespunzător (excluzând, în cazul
              în care este necesar, taxele de livrare inițiale, taxele de
              manipulare a numerarului, și orice taxe vamale, și excluzând
              taxele de returnare). Putem face o deducere din rambursare pentru
              pierderea în valoare, dacă pierderea este rezultatul manipulării
              inutile din partea dumneavoastră.
            </p>
            <p>
              <strong>Condiții generale aplicabile Returnărilor</strong>
            </p>
            <p>
              Pentru a beneficia de o rambursare, toate articolele trebuie
              returnate în termen de 14 zile de la primirea Comenzii în
              următoarele condiții:
            </p>
            <ul style={{ color: "lightgray" }}>
              <li>
                • nemodificate, neutilizate, complet sigilate (în aceeași stare
                în care le-ați primit de la noi sau de la agenții noștri). De ex
                : Pantofii nu trebuie să aibă nicio deteriorare a tălpilor sau a
                altor părți;
              </li>
              <li>
                • în ambalajul/ cutia/ husa originală, inclusiv benzi de
                protecție, cu toate etichetele originale de marcă și produs cu
                instrucțiunile încă atașate. Cardurile de autenticitate, în
                cazul în care au fost furnizate, trebuie returnate, de asemenea.
              </li>
              <li>• însoțite de Confirmarea originală a Comenzii.</li>
              <li>
                • inspecția inițială la punctul de colectare nu constituie o
                garanție a eligibilității dumneavoastră pentru o rambursare
                integrală.
              </li>
              <li>
                • nu putem oferi returnări pentru următoarele categorii de
                produse: produse digitale, produse neachiziționate prin
                intermediul Site-ului și produse vândute pe alte site-uri web,
                precum și produse desigilate, cum ar fi reviste, parfumuri și
                lacuri de unghii.
              </li>
              <li>
                • ne rezervăm dreptul de a monitoriza returnările și de a refuza
                Comenzi de la clienți cu niveluri excesive de returnări.
              </li>
            </ul>
            <p>
              Nimic în această secțiune Returnări nu are drept scop afectarea
              drepturilor de consum pe care le aveți în conformitate cu
              legislația Uniunii Europene (UE).
            </p>
            <p>
              <strong>Plată</strong>
            </p>
            <p>
              În prezent acceptăm următoarele tipuri de plată pentru comenzile
              plasate pe site-ul nostru: Google Pay, Apple Pay, Bancontact,
              VISA, MASTERCARD. Plata va fi efectuată în momentul în care
              plasați Comanda.
              <br />
              Prin introducerea detaliilor de plată pe site-ul nostru, garantați
              că sunteți autorizat să plătiți folosind acele detalii. Ne
              rezervăm dreptul de a respinge comenzile fără nicio răspundere
              față de dumneavoastră în cazul în care considerăm că plățile nu
              sunt autorizate, în cazul în care metoda de plată nu este validă
              sau în cazul în care nu considerăm că sunteți autorizat să
              utilizați tipul de plată relevant.
              <br />
              Luăm măsuri rezonabile pentru a asigura securitatea site-ului
              nostru. Toate tranzacțiile cu carduri de credit/debit pe acest
              site sunt procesate folosind Stripe, o platformă de plată online
              securizată care criptează detaliile cardului dumneavoastră într-un
              mediu gazdă securizat. Dacă sunteți un utilizator înregistrat, vom
              utiliza în mod securizat detaliile cardului dumneavoastră de
              credit/debit în sistemele noastre. Aceste detalii vor fi utilizate
              complet criptate și doar pentru a procesa tranzacțiile cu cardul
              pe care le-ați inițiat, și nu vor fi stocate niciodată pe serverul
              nostru.
              <br />
              Pentru a vă asigura că experiența dumneavoastră de cumpărături
              este sigură, simplă și securizată, Paradise Competitions
              utilizează tehnologia SSL (Secure Socket Layer).
            </p>
            <p>
              <strong>Prețuri</strong>
              <br />
              <br />
              <p>Prețurile de pe site sunt afișate în moneda RON.</p>
              <p>
                Aceste prețuri NU includ nici un serviciu suplimentar pe care
                ați putea decide să-l asociați achiziției, costurile de livrare
                și taxele sau impozitele la care poate fi supusă achiziția.
                Costurile de livrare, taxele și impozitele (dacă există) depind
                de destinația la care ne solicitați să livrăm și de natura
                bunurilor și a serviciilor suplimentare pe care le-ați selectat.
                Orice costuri suplimentare de acest fel va vor fi anuntate
                inainde de expediere.
              </p>
              <p>
                Prețul aplicabil comenzii dumneavoastră va fi prețul valabil la
                momentul în care comanda dumneavoastră este acceptată.
              </p>
              <p>
                Dacă efectuați o achiziție folosind un card de plată denominat
                într-o altă monedă, plata va fi supusă cursului de schimb
                valutar străin aplicat de emitentul cardului dumneavoastră la
                momentul în care plățile sunt finalizate.
              </p>
              <strong>Disponibilitatea Produselor</strong>
              <br />
              <br />
              <p>
                Toate produsele afișate pe site au indicată starea lor de
                disponibilitate. Cele marcate ca "Vândut" înseamnă că nu mai
                sunt disponibile pe site. Dar unele pot fi făcute la comandă,
                așa că dacă vă interesează ceva, puteți întreba agenții noștri
                și vă vor oferi informațiile necesare despre produsul dorit.
                Dacă ați comandat un produs de pe site și acesta nu mai este
                disponibil, vă vom anunța prin telefon, WhatsApp sau e-mail cât
                mai curând posibil pentru a vă anula comanda.
              </p>
              <strong>Înregistrare</strong>
              <br />
              <br />
              <p>
                Pentru a beneficia de serviciile acestui site, puteți utiliza
                site-ul pe bază de "check-out" de oaspeți sau puteți crea un
                cont și să vă înregistrați detaliile cu noi.
              </p>
              <p>
                Înregistrările sunt deschise pentru toate persoanele care au
                implinit 18 ani sau peste, din orice parte a lumii. Entitățile
                comerciale nu sunt eligibile să se înregistreze cu noi.
              </p>
              <p>
                Ne rezervăm dreptul de a închide contul unui client în orice
                moment, dacă considerăm că acesta abuzează de serviciile
                noastre, alte clienți sau personalul nostru, prin limbaj, sau în
                orice alt mod sau dacă avem convingerea că nu este într-adevăr
                clientul care se înregistrează.
              </p>
              <p>
                Este responsabilitatea dumneavoastră continuă să vă asigurați că
                toate detaliile personale și de plată pe care ni le furnizați
                sunt adevărate, valabile în prezent, exacte și complete. Trebuie
                să furnizați numele real, numărul de telefon și adresa de e-mail
                și să confirmați că sunteți persoana menționată în informațiile
                de facturare. Puteți actualiza detaliile în profilul contului
                dumneavoastră personal.
              </p>
              <p>
                Vă rugăm să păstrați detaliile contului și parola confidențiale
                și în siguranță. Nu suntem responsabili pentru nicio pierdere
                suferită prin utilizarea incorectă a acestor detalii. Agenții
                noștri de servicii pentru clienți nu vă vor solicita niciodată
                parola: suntem angajați să respectăm Politica noastră de
                confidențialitate. Vă rugăm să ne anunțați cât mai curând
                posibil dacă credeți că contul dumneavoastră a fost compromis.
                Sunteți de acord că veți fi personal responsabil pentru
                utilizarea site-ului nostru și pentru toate comunicările și
                activitățile dumneavoastră pe și în legătură cu acest site.
              </p>
            </p>
            <p>
              <strong>Comenzi</strong>
              <br />
              <br />
              <p>
                Atunci când plasați o comandă cu noi, vă angajați să cumpărați
                produsele descrise în acea comandă, la prețul indicat, inclusiv
                orice taxe de livrare, taxe de manipulare a numerarului și taxe
                vamale, în cazul în care este cazul.
              </p>
              <p>
                Comenzile sunt supuse acceptării noastre, pe care o putem refuza
                la discreția noastră exclusivă, inclusiv din motive precum
                ineligibilitatea, imposibilitatea de a confirma autorizarea
                plății, suspiciuni de fraudă, restricții de livrare și
                disponibilitate de stoc. Produsele din coșul dumneavoastră de pe
                site nu sunt rezervate până când comanda dumneavoastră nu este
                plătită.
              </p>
              <p>
                Site-ul nostru sau centrul nostru de asistență pentru clienți
                poate conține sau poate furniza informații privind
                disponibilitatea mărfurilor. Aceste informații pot fi folosite
                pentru a estima probabilitatea ca un articol să fie disponibil
                imediat după plasarea comenzii. Din păcate, nu putem garanta că
                un articol declarat ca fiind disponibil va fi într-adevăr
                disponibil imediat, deoarece stocurile pot varia semnificativ pe
                parcursul zilei. În cazuri rare, un produs poate fi disponibil
                atunci când plasați o comandă, dar să fie epuizat până când
                procesăm comanda. În cazul în care acest lucru se întâmplă, vă
                vom notifica.
              </p>
              <p>
                Primirea comenzii dumneavoastră va fi confirmată prin e-mail. Cu
                toate acestea, comanda este confirmată doar atunci când primiți
                o notificare de la noi confirmând expedierea articol(elor)
                relevante. Nicio altă parte în afara noastră nu are autoritatea
                de a confirma acceptarea comenzii.
              </p>
            </p>
            <p>
              <strong>Descrieri</strong>
              <br />
              <br />
              <p>
                La momentul publicării pe site-ul nostru, toate descrierile
                produselor sunt considerate exacte. Ne străduim, de asemenea, să
                reprezentăm produsele de pe site-ul nostru cât mai precis
                posibil, cu toate acestea culorile și rezoluția pot varia în
                funcție de afișajul monitorului dumneavoastră. Toate
                măsurătorile și greutățile sunt aproximative.
              </p>
            </p>

            <p>
              <strong>Activități de stimulare a vânzărilor</strong>
              <br />
              <br />
              Paradise Competitions este încântat să ofere reduceri la orice
              moment și să desfășoare simultan și temporar mai multe activități
              de stimulare a vânzărilor sau promoții, cum ar fi campanii
              publicitare cu concursuri din care rezultă alocari de premii.
              Disponibilitatea și prețurile produselor sunt la discreția
              exclusivă a Paradise Competitions și vor fi specificate pe site.
              Pentru codurile de promovare și vouchere, nu există o alternativă
              în numerar.
              <br />
              <br />
              <strong>Angajament legal</strong>
              <br />
              <br />
              Activitățile noastre și concursurile sunt conforme cu Legea
              Economică Romana și sunt guvernate de aceasta.
              <br />
              <br />
              <p>
                Concursurile noastre sunt conforme cu reglementarea Comisiei de
                Jocuri de Noroc din Romania, deoarece nu există nicio cerință de
                plată pentru a participa, deoarece există o rută de intrare
                gratuită disponibilă, si nici un cost suplimentar achizitionarii
                unui produs, așa cum este stipulat conform art. 41 din O.G
                nr.99/2000 si Ordonantei de Urgenta nr.77 din 2009 și așa cum
                este explicat mai jos.
              </p>
              <p>
                Aspectele legate de concursuri vor fi rezolvate în conformitate
                cu Legea Romană, iar instanțele vorbitoare de limbă romana ce au
                jurisdicție exclusivă.
              </p>
              <p>
                Prin acceptarea Termenilor, sunteți de acord să rezolvați toate
                disputele prin arbitraj individual obligatoriu, ceea ce înseamnă
                că renunțați la orice drept de a avea acele dispute soluționate
                de un judecător și că renunțați la dreptul de a participa la
                acțiuni colective, arbitraje colective sau acțiuni
                reprezentative.
              </p>
              <p>
                Nimic în acești Termeni nu vă va împiedica să faceți reclamații
                în măsura în care vă exercitați drepturile legale.
              </p>
            </p>
            <p>
              Răspunderea noastră totală maximă agreată față de fiecare
              Câștigător va fi limitată la valoarea totală a fiecărui Premiu
              câștigat de Câștigătorul relevant (egal cu 70% din suma de bani
              obținută în cadrul acelei competiții). Răspunderea noastră totală
              maximă agreată față de dumneavoastră, în cazul în care nu sunteți
              un Câștigător, va fi limitată la suma pe care ați plătit-o pentru
              a cumpăra produse care oferă intrări gratuite la Competiții în
              primele 12 luni de la jocul oricărei Competiții.
            </p>
            <p>
              Regulile noastre de concurs nu vor crea si nu vor fi interpretate
              ca fiind încheierea vreunui fel de contract, parteneriat sau alt
              acord între orice Participant și noi.
            </p>
            <p>
              <strong>Participarea la un concurs online</strong>
            </p>
            <p>
              Pentru a participa la un concurs online, puteți obține o intrare
              gratuită dacă câștigați unul dintre concursurile noastre "like,
              share and tag" pe care le desfășurăm pe mai multe postări de pe
              Facebook.
            </p>
            <p>Sau,</p>
            <p>
              Puteți cumpăra unul dintre produsele noastre cu un bilet de
              intrare gratuit pentru a participa la un concurs online. Doar
              utilizatorii înregistrați pot participa la un concurs. Urmați
              pașii publicați pe site-ul nostru. Când ați intrat cu succes
              într-un concurs online, vă vom confirma acest lucru prin e-mail și
              veți vedea acest lucru în contul dumneavoastră personal.
            </p>
            <p>
              Un Participant poate participa la fiecare concurs de până la
              maximum 100 de ori. Cu toate acestea, un cont sub numele
              Participantului poate avea o cantitate nelimitată de intrări, cu
              condiția să fie în numele altor persoane. Acest maxim poate fi
              limitat la discreția noastră exclusivă, dacă este specificat pe
              pagina produsului concursului: de exemplu, un concurs cu o șansă
              mică putem limita la 2 intrări per persoană.
            </p>
            <p>
              Participantul este de acord să permită Paradise Competitions să
              afișeze numele său pe site, în scopul anunțării Câștigătorului
              Concursului. De obicei, acest lucru se întâmplă în termen de 24 de
              ore de la determinarea Câștigătorului.
            </p>
            <p>
              Participanții autorizează Promotorul să introducă detaliile lor
              personale în baza de date și să utilizeze informațiile în
              conformitate cu prevederile Legii Europene de Protecție a Datelor
              din 2018 (GDPR) și în conformitate cu termenii Politicii noastre
              de Confidențialitate.
            </p>
            <p>
              În cazul în care participați la un concurs, prin acceptarea
              acestor termeni și condiții, confirmați că nu încălcati nicio lege
              din țara de domiciliu în ceea ce privește legalitatea participării
              la concursurile noastre. Nu vom fi responsabili pentru nicio
              persoană care a participat la vreuna dintre Competițiile noastre
              în mod ilegal. În caz de îndoială, ar trebui să părăsiți imediat
              site-ul web și să verificați cu autoritățile relevante din țara
              dumneavoastră.
            </p>
            <p>
              În măsura permisă de lege, toate intrările devin proprietatea
              noastră și nu vor fi returnate. Ne rezervăm dreptul de a refuza
              sau descalifica orice intrări incomplete dacă există motive
              întemeiate de a crede că un Participant a încălcat oricare dintre
              acești Termeni. O intrare va fi declarată nulă (fără nicio
              restituire) dacă Participantul se angajează în:
            </p>
            <p>
              (1) orice formă de comportament neloial și fraudulos (real sau
              aparent);
              <br />
              (2) reprezentare frauduloasă;
              <br />
              (3) ascundere frauduloasă;
              <br />
              (4) hacking sau interferență cu funcționarea corectă a site-ului
              web; sau
              <br />
              (5) modificarea sau utilizarea neautorizată a oricăruia dintre
              codurile care constituie site-ul.
            </p>

            <p>
              <strong>Închiderea unui concurs</strong>
            </p>
            <p>
              Durata sau "perioada de promovare" a fiecărui concurs este
              specificată pe pagina produsului concursului. Vă rugăm să
              consultați site-ul nostru pentru detalii specifice. Fiecare
              concurs se închide atunci când este ales ultimul număr,dupa
              aceasta, nu se vor mai accepta alte intrări. Datele tragerii la
              sorți și specificațiile privind modul și momentul în care durata
              unui concurs poate fi prelungită sunt, de asemenea, anunțate pe
              site. Paradise Competitions își rezervă dreptul de a prelungi data
              de închidere inițială cu până la 6 săptămâni, la discreția sa
              exclusive si Paradise Competitions poate decide la discreția sa
              exclusivă să efectueze o tragere la sorți anticipată odată ce au
              fost selectate 50% din bilete.
            </p>
            <p>
              <strong>Judecarea concursului</strong>
            </p>
            <p>
              Mașina noastră de extragere a bilelor 100% aleatoare va determina
              Câștigătorul(ii) fiecărui concurs.
            </p>
            <p>
              Numărul de intrări primite per concurs va determina câte bile vor
              fi introduse în mașină. Fiecare concurs va avea o extragere a
              bilelor de litere și a bilelor de numere. Există maxim 26.000 de
              combinații diferite de intrări. Fiecare extragere va fi anunțată
              pe site și transmisă în direct pe Facebook.
            </p>
            <p>
              Numărul de câștigători ai fiecărui concurs este stabilit în
              prealabil.
            </p>
            <p>
              În cazul în care trebuie să fie aleși mai mulți câștigători
              într-un singur concurs, ordinea de selecție este prima premiu, a
              doua premiu, etc.
            </p>
            <p>
              În cazul în care un concurs a fost epuizat în momentul tragerii în
              direct, premiul prevăzut va fi acordat câștigătorului(lor).
            </p>
            <p>
              În cazul în care concursul nu a fost epuizat după ultima
              prelungire a duratei concursului, atunci va fi acordat un premiu
              alternativ în valoare de 70% din suma de bani obținută în cadrul
              acestui concurs, minus costurile de plată și cheltuielile de
              marketing. În cazul în care efectuăm o tragere la sorți anticipată
              și închidem concursul mai devreme indiferent de câte bilete au
              fost vândute, putem totuși acorda premiul.
            </p>
            <p>
              În ambele astfel de evenimente, doar Participanții la concurs vor
              fi incluși în această tragere. În mod obișnuit, în cazul în care o
              "intrare neutilizată" este extrasă din extragere, întreaga
              combinație de litere și numere va fi extrasă din nou. Judecata
              Concursului nostru este definitivă și nu se va intra în nicio
              corespondență cu privire la rezultatul Concursului după
              determinarea Câștigătorului(lor) conform descrierii de mai sus.
            </p>
            <p>
              În cazul în care mai multe concursuri vor fi trase în timpul unei
              transmisiuni în direct, Paradise Competitions poate decide la
              discreția sa exclusivă care concurs va fi tras mai întâi, al
              doilea, etc.
            </p>
            <p>
              În cazul în care mașina automată de extragere a bilelor nu este
              disponibilă, va fi folosit un Generator de Numere Aleatorii
              online.
            </p>
            <p>
              În caz de forță majoră - care nu s-a întâmplat până acum - din
              cauza căreia trebuie să închidem un concurs mai devreme,
              Câștigătorul(ii) vor fi totuși selectați din toate Intrările
              valabile primite înainte de data și ora închiderii. Paradise
              Competitions își rezervă dreptul, la discreția sa exclusivă, de a
              închide sau anula un Concurs în orice moment, fie înainte, fie
              după ce au fost vândute bilete. Dacă Concursul este anulat, vom
              returna orice Taxe de Intrare plătite fiecărui Participant (fie
              prin rambursarea cardului bancar, fie prin creditul magazinului).
              În cazul în care Taxa de Intrare este returnată, Promotorul nu va
              avea nicio altă răspundere față de Participant sau față de orice
              altă persoană..{" "}
            </p>
            <p>
              <strong>Câștigători</strong>
            </p>
            <p>
              Paradise Competitions va încerca să contacteze
              Câștigătorul/Câștigătorii folosind numerele de telefon și adresa
              de e-mail furnizate la momentul inscrierii (sau ulterior
              actualizate) și păstrate în siguranță în baza noastră de date.
              Este responsabilitatea exclusivă a Câștigătorului/ Câștigătorilor
              să verifice și să actualizeze aceste detalii. Dacă din orice motiv
              acestea sunt înregistrate incorect, nu vom fi responsabili.
              Participanții trebuie să verifice cu atenție dacă detaliile de
              contact sunt înregistrate corect.
            </p>
            <p>
              În cazul în care, din orice motiv, nu putem contacta un Câștigător
              în termen de 30 de zile de la încheierea unui Concurs, sau
              Câștigătorul nu confirmă acceptarea premiului, sau Câștigătorul
              este descalificat ca rezultat al încălcării oricăreia dintre
              aceste Condiții, Câștigătorul va pierde premiul iar acesta va
              rămâne în posesia și proprietatea Paradise Competitions.
            </p>
            <p>
              După primirea și verificarea detaliilor de contact, Câștigătorii
              vor fi contactați pentru a face aranjamente pentru predarea
              premiului. Biletul câștigător plus dovada identității trebuie
              furnizate înainte de ridicarea premiului și TREBUIE să se
              potrivească cu cele ale detaliilor furnizate la înregistrarea în
              concursul respectiv. Dovada biletului câștigător este fișierul PDF
              trimis ca confirmare a comenzii. Orice neîndeplinire a acestei
              obligații poate duce la descalificarea Câștigătorului și la
              reținerea premiului de către Paradise Competitions.
            </p>
            <p>
              Următorul pas: Momentul tău să strălucești :-D Toți Câștigătorii
              Premiului I sunt obligați să furnizeze și/sau să pozeze pentru
              fotografii și videoclipuri la acceptarea premiului, care pot fi
              folosite în viitoarele activități de marketing (cum ar fi pe
              pagina noastră de podium a câștigătorilor) în legătură cu
              concursul și pentru identificarea lor ca și câștigători ai unui
              concurs, fără nicio compensație suplimentară, cu excepția cazului
              în care legea interzice acest lucru. Neparticiparea poate duce la
              reținerea premiului de către noi.
            </p>
            <p>
              <strong>Premii</strong>
            </p>
            <p>
              Toate premiile sunt selectate și achiziționate de managerii
              Paradise Competitions, iar în acest caz câștigătorul va primi
              premiul respectiv. Detaliile fiecărui premiu pot fi găsite pe
              site-ul web pe paginile concursurilor. Paradise Competitions
              deține premiul până la momentul predării. Paradise Competitions nu
              își asumă nicio responsabilitate pentru premiul acordat după
              livrare. Odată ce câștigătorul primește premiul, Paradise
              Competitions nu asigură premiul. Nu există nicio asigurare
              însoțită de premii și nu putem fi ținuți responsabili pentru
              premiu odată ce acesta a fost predat câștigătorului.
            </p>
            <p>
              Toate cheltuielile participanților și colectarea premiului sunt
              responsabilitatea exclusivă a Câștigătorului, de la Adresa noastră
              de colectare, cu excepția cazului în care s-a convenit altfel
              între echipa noastră de service și Câștigător. Decizia între (1)
              Câștigătorul care colectează premiul de la noi și (2) noi care
              livrăm premiul Câștigătorului este la discreția exclusivă a
              Paradise Competitions și depinde de disponibilitatea premiului și
              de termenii furnizorului de premii.
            </p>
            <p>
              În general, majoritatea premiilor vor fi livrate de noi la adresa
              câștigătorului. Premiile precum mașini, motociclete și vehicule
              motorizate trebuie în mod normal colectate de către câștigători.
              Adresa noastră de colectare va fi furnizată anterior.
            </p>
            <p>
              Livrarea la adresa de domiciliu a câștigătorului din Romania este
              gratuită. Paradise Competitions își rezervă dreptul de a percepe
              câștigătorului taxe de livrare dacă acesta dorește ca premiul să
              fie livrat în altă parte decât zonele specificate.
            </p>
            <p>
              Toate premiile sunt supuse termenilor și condițiilor furnizorului
              de premii, producătorului, furnizorului și transportatorului.
              Paradise Competitions nu implică sau nu oferă nicio garanție și nu
              acceptă nicio evaluare a premiului, nici nu garantează calitatea,
              potrivirea, performanța sau starea tehnică a bunurilor sau
              serviciilor oferite ca premii. Participanții trebuie să își asume
              propriile decizii prin propriile lor anchete și consultări legale
              înainte de a intra în concurs. Fiecare premiu trebuie acceptat așa
              cum a fost acordat și nu poate fi transferat sau convertit în alte
              înlocuitori.
            </p>
            <p>
              <strong>Stocarea premiilor</strong>
            </p>
            <p>
              Paradise Competitions poate stoca un premiu gratuit timp de până
              la 30 de zile, după care premiul va fi înmânat câștigătorului.
              Dacă premiul trebuie depozitat mai mult de 30 de zile, atunci
              acest cost va fi în întregime în sarcina Câștigătorului, iar acest
              cost trebuie plătit de către Câștigător înainte ca acesta să
              primească Premiul.{" "}
            </p>
            <p>
              <strong>Limite de răspundere</strong>
            </p>
            <p>
              Cu excepția decesului sau vătămării personale cauzate de
              neglijența noastră, Paradise Competitions nu va fi răspunzător
              pentru nicio pierdere (inclusiv pierderea consecințială și
              economică) suferită sau suportată de orice persoană sau
              proprietate ca rezultat al oricărei acțiuni sau omisiuni ale
              Paradise Competitions, a angajaților sau agenților săi în
              dezvoltarea, planificarea și administrarea Competiției,
              distribuția Premiului sau urmărirea distribuției Premiului către
              Câștigător și, dacă este cazul, către orice familie/persoane care
              însoțesc Câștigătorul.
            </p>
            <p>
              Paradise Competitions nu își asumă nicio responsabilitate pentru
              erori sau omisiuni conținute în detaliile despre Premiu, descriere
              sau specificații sau în orice altă parte a Website-ului. Este
              responsabilitatea fiecărui Participant (și în special a
              Câștigătorului) să se asigure de exactitatea acestor detalii
              și/sau a conținutului site-ului. Paradise Competitions nu va fi
              răspunzător pentru nicio pierdere suferită de un Participant ca
              rezultat al intrărilor incomplete sau a comunicărilor eșuate ale
              computerelor sau pentru orice pierdere suferită ca rezultat al
              utilizării Website-ului.
            </p>
            <p>
              Paradise Competitions nu își asumă responsabilitatea pentru
              intrările pe care nu le primim de la Participant din cauza
              eșecurilor în sistemele informatice, a altor defecțiuni, a
              traficului ridicat pe Internet, a defecțiunilor hardware, a
              eșecului software, a defectelor serverului sau oricărui alt motiv,
              inclusiv eroarea umană. Paradise Competitions va depune cele mai
              bune eforturi pentru a se asigura că software-ul și site-urile
              folosite pentru a opera Competițiile sale funcționează corect și
              precis pe cele mai recente versiuni ale browserelor populare de
              internet, tablete și mobile. În scopul evitării oricăror îndoieli,
              doar biletul înregistrat în sistemele noastre, indiferent cum este
              afișat sau calculat, va fi introdus în Competiția relevantă și
              Paradise Competitions nu va fi tras la răspundere pentru nicio
              intrare în competiție care apare ca rezultat al unui software
              defectuos sau al altui eveniment.
            </p>
            <p>
              Paradise Competitions va depune cele mai bune eforturi pentru a
              acorda premiul pentru o competiție Participantului corect. În
              cazul în care, din cauza unei defecțiuni legate de calculator sau
              a unei erori umane, premiul este acordat în mod incorect, Paradise
              Competitions își rezervă dreptul de a revendica premiul
              competiției și de a-l acorda participantului corect, la discreția
              sa exclusivă și fără admiterea vreunei răspunderi. Paradise
              Competitions nu va fi răspunzătoare pentru nicio pierdere
              economică sau altă pierdere consecințială suferită sau suportată
              de către orice persoane cărora li s-a acordat în mod incorect un
              premiu, și nu se va acorda nicio compensație.
            </p>
            <p>
              Paradise Competitions nu va fi răspunzător pentru nicio întârziere
              sau neîndeplinire a oricărei obligații în temeiul acestor Termeni,
              în cazul în care întârzierea sau eșecul rezultă din orice cauză
              din afara controlului său rezonabil, inclusiv circumstanțe de
              forță majoră, cum ar fi o pandemie, tulburări industriale,
              elemente ale naturii, blocuri sau ordine guvernamentale.
            </p>
            <p>
              <strong>Notificare privind protecția datelor</strong>
            </p>
            <p>
              În conformitate cu Regulamentul General privind Protecția Datelor
              (GDPR) și în conformitate cu Politica noastră de
              confidențialitate, orice date cu caracter personal pe care ni le
              furnizați sau pe care ne autorizați să le obținem de la o terță
              parte, cum ar fi o companie de card de credit, vor fi utilizate de
              noi pentru a administra Serviciile și Competițiile noastre și
              pentru a îndeplini premiile, acolo unde este cazul. Pentru a
              procesa, înregistra și utiliza datele dvs. cu caracter personal,
              putem dezvălui aceste informații către (1) orice companie de card
              de credit al cărei nume îl furnizați; (2) orice persoană căreia îi
              propunem să transferăm oricare dintre drepturile și/sau
              responsabilitățile noastre în temeiul oricărei înțelegeri pe care
              o putem avea cu dvs.; (3) orice persoană căreia îi propunem să
              transferăm Serviciile noastre sau orice parte a acestora; (4)
              pentru a respecta orice cerință legală sau reglementară pe care o
              avem în orice țară; și (5) pentru a preveni, detecta sau urmări
              frauda și alte infracțiuni. Pentru a procesa, utiliza, înregistra
              și divulga datele dvs. cu caracter personal, putem avea nevoie să
              transferăm astfel de informații în afara Romaniei, în cazul în
              care suntem responsabili pentru a ne asigura că datele dvs. cu
              caracter personal continuă să fie protejate în mod adecvat pe
              parcursul unui astfel de transfer.
            </p>
            <p>
              <strong>Pentru a participa la o Competiție prin Poștă</strong>
            </p>
            <p>
              Pentru a participa la o competiție Paradise Competitions gratuit,
              puteți intra în orice competiție prin poștă. Trebuie să ne
              trimiteți: “Expeditor: numele dvs, adresa, data nașterii, adresa
              de e-mail, numărul de telefon de contact și titlul competiției la
              care doriți să participați” pe verso-ul unei cărți poștale
              neînchise. Separat de expeditor; pe verso sunt datele personale
              așa cum este enumerat mai sus. Intrările poștale trebuie să fie
              expediate exclusiv către: Campanie # (# înseamnă numărul unic al
              competiției), P.O.Box 155, O.P.Buc 15, București, și trebuie să
              ajungă înainte de data de închidere a Competiției. Taxa poștală
              trebuie plătită. Acestea fiind spuse, nici intrările multiple
              într-un singur plic, nici intrările direct în căsuța scrisă nu vor
              fi acceptate. Intrările poștale sunt limitate la un cont la o
              singură Intrare în fiecare Competiție și o singură Intrare pe
              cărticica poștală. Fiecărei intrări poștale valabile i se va
              atribui un număr aleatoriu de către Paradise Competitions.
              Participantul va fi înscris în tragere în același mod ca orice
              altă intrare online. Toate Intrările gratuite prin posta, vor fi
              tratate în exact același mod ca intrările gratuite online. Dacă
              este cazul, acești Termeni se aplică și intrărilor poștale. Toate
              intrările gratuite sunt procesate în fiecare luni, începând cu ora
              11 dimineața. Dacă intrarea dvs. a fost primită la timp înainte de
              începerea tragerii, veți fi introdus în lista de intrări pentru
              acea tragere. Trimiterea unei intrări poștale nu garantează
              participarea la o tragere. Intrările poștale nu vor fi luate în
              considerare dacă o competiție se vinde complet înainte de ora de
              procesare a zilei respective. Nu putem fi trași la răspundere
              pentru timpul de livrare al poștei. Trebuie să includeți titlul
              Competiției exact așa cum este afișat pe site. Trebuie să fiți
              înregistrați ca și clienți pe site-ul nostru înainte de a trimite
              intrarea dvs. poștală. Dacă nu corespundeți cu un Cont înregistrat
              cu intrarea dvs. poștală, aceasta va duce la eliminarea intrării
              dvs.
            </p>
            <p>
              <strong>Utilizare neadecvată</strong>
            </p>
            <p>
              Site-ul nostru este destinat exclusiv utilizării dvs. personale și
              non-comerciale. Sunteți de acord că veți utiliza contul dvs., veți
              intra în competiții, trageri la sorți și veți accesa și/sau
              utiliza Site-ul într-un mod corespunzător și legal. Nu veți (1)
              primi, accesa și/sau transmite niciun conținut obscen,
              pornografic, amenințător, rasist, amenințător, ofensator,
              defăimător, care încalcă încrederea, încalcă orice drept de
              proprietate intelectuală (inclusiv, fără limitare, dreptul de
              autor) și/sau altfel inacceptabil și/sau ilegal, (2) transmiteți
              intenționat și/sau imprudent orice conținut (inclusiv, fără
              limitare, viruși) prin Site și/sau software-ul nostru și sistemele
              IT care vor cauza sau sunt susceptibile să cauzeze (i) prejudicii
              și/sau vătămări, în orice grad, Site-ului, software-ului nostru și
              sistemelor IT deținute și/sau operate de noi și/sau altele, și/sau
              (ii) pierderea și/sau deteriorarea datelor, (3) piratați, faceți
              cereri excesive de trafic sau cauzați orice deteriorare a
              funcțiilor oricărui sistem informatic, livrați sau redirecționați
              scrisori în lanț, "junk mail" de orice fel, sondaje, concursuri,
              scheme piramidale sau să vă angajați în orice comportament menit
              să împiedice alții să intre în Paradise Competitions, sau să
              utilizeze Site-ul și/sau orice alt site web in orice alta maniera,
              și/sau care este probabil să prejudicieze reputația și/sau
              afacerea Promotorului și/sau a oricărui terț, și/sau (4)
              autorizați sau permiteți cuiva să facă oricare dintre acestea.
            </p>
            <p>
              <strong>Indemnizare</strong>
            </p>
            <p>
              La cererea noastră, sunteți de acord să ne despăgubiți, să ne
              compensați în întregime, să ne apărați și să ne mențineți Paradise
              Competitions și membrii echipei noastre, agenți, afiliați și
              furnizori, fără vinovăție, imediat la cerere, de toate
              răspunderile, revendicările, cheltuielile, daunele și pierderile,
              inclusiv taxele legale, pe care le putem suferi în orice cerere,
              proces legal sau cerere făcută de orice terț, rezultată din orice
              încălcare a Termenilor de către dvs., inclusiv utilizarea de către
              orice alte persoane care accesează Site-ul nostru folosindu-vă
              Contul cauzată de acțiunea sau inacțiunea dvs.
            </p>
            <p>
              <strong>Cedarea drepturilor și obligațiilor</strong>
            </p>
            <p>
              Nu puteți atribui sau transfera în alt mod (în întregime sau în
              parte) drepturile și/sau obligațiile dvs. în temeiul acestor
              Termeni. Orice încălcare a acestei reguli poate duce la
              suspendarea sau întreruperea imediată a utilizării contului dvs.
              de către Paradise Competitions. Paradise Competitions poate
              atribui drepturile și/sau obligațiile sale în temeiul acestor
              Termeni în întregime sau în parte către orice terț la discreția sa
              exclusivă și fără consimțământul dvs. Paradise Competitions poate
              de asemenea să atribuie acești Termeni în întregime, fără
              consimțământul dvs., succesorului său de interes în legătură cu o
              fuziune, reorganizare sau vânzarea intregului sau a unei parti
              substantiale din active sau echitate. Acesti termeni vor lega si
              vor beneficia compania noastra, precum si succesorii si asociatii
              sai permisi.
            </p>
            <p>
              <strong>Drepturile de proprietate intelectuala</strong>
            </p>
            <p>
              Paradise Competitions va apăra drepturile sale de proprietate
              intelectuală în fața unui tribunal. Paradise Competitions este
              protejat de drepturile de proprietate intelectuală naționale și de
              prevederile tratatelor internaționale. Orice încălcare a
              drepturilor noastre de proprietate intelectuală și orice
              reproducere chiar cu mici modificari dar care pastreaza idea si
              continutul sau redistribuire a siteului este expres interzisă și
              poate atrage sancțiuni civile și penale.
            </p>
            <p>
              Paradise Competitions{" "}
              <strong>este proprietarul și deține licența</strong> tuturor
              drepturilor de autor, mărcilor comerciale și altor drepturi de
              proprietate intelectuală referitoare la Ideea sa de Afacere,
              Servicii, Competiții și Conținutul Site-ului, iar dumneavoastră nu
              veți dobândi niciun drept asupra acestora. "
              <strong>Conținut</strong>" este definit ca orice grafică, imagini,
              video, audio, text și idei originale, cum ar fi (prima și singura
              din lume) felicitarea digitală Fortune Cookie (conceptul unui
              videoclip de dimensiuni de portret care afișează un mesaj Fortune
              Cookie cu o surpriză dezvăluită la sfârșit). Nu aveți voie să
              eliminați, să modificați, să copiați, să dezvăluiți, să
              distribuiți, să faceți disponibil, să transmiteți, să afișați, să
              reproduceți, să publicați, să licențiați, să exploatați comercial,
              să creați lucrări derivate, să transferați, să minați date, să
              decompilați sau să vindeți niciun <strong>Conținut</strong>,
              software, produse sau Servicii conținute în cadrul Site-ului
              nostru.
            </p>
            <p>
              Mărcile înregistrate, ideile originale, mărcile de serviciu,
              logourile, denumirile comerciale, identificatoarele de sursă
              și/sau denumirile proprietare ("Mărci comerciale") ale
              Promotorului utilizate în legătură cu Serviciile Site-ului sunt
              mărci comerciale ale Paradise Competitions. Mărcile comerciale ale
              terțelor părți utilizate în legătură cu Serviciile Site-ului sunt
              utilizate doar în scopuri de identificare și pot fi proprietatea
              deținătorilor lor. Nu aveți voie să eliminați, să mascați sau să
              modificați nicio notificare privind drepturile de autor, mărcile
              comerciale sau alte drepturi de proprietate intelectuală în orice
              material obținut de pe Site.
            </p>
            <p>
              <strong>Acordul integral</strong>
            </p>
            <p>
              Recunoașteți și sunteți de acord că acești Termeni constituie
              acordul complet și exclusiv între noi referitor la obiectul
              acestor Termeni și înlocuiesc și guvernează toate propunerile
              anterioare, acordurile sau alte comunicări.
            </p>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default TermsConditions;
