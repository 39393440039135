import axios from "axios";
import * as types from "../types";
import { getCartData } from "../cart/cart";
import { getTokenIncludedConfig } from "../common";
import { REQUEST_URL } from "../../constant/Constant";
import { SuccessToast, ErrorToast } from "../../components/toaster/toast";

export const confirmPayment =
  (setPaymentStatus, setPaymentLoading, get_pdfs) => async (dispatch) => {
    setPaymentLoading(true);
    dispatch({
      type: types.WORLDLINE_PAYMENT_REQUEST_START,
    });

    await axios
      .get(REQUEST_URL + `/api/carts/check/order/`, getTokenIncludedConfig())
      .then((response) => {
        if (response.status === 200) {
          dispatch(sendInvoice(response.data, get_pdfs));
          dispatch(getCartData());
          SuccessToast(
            "Your order has been placed and you will recieve the confirmation email shortly"
          );
          setPaymentLoading(false);
          setPaymentStatus(true);
          dispatch({
            type: types.CONFIRM_WORLDLINE_PAYMENT_SUCCESS,
          });
        }
      })
      .catch(function (error) {
        ErrorToast(
          "Your payment was unsuccessful please try again or contact the support. Thanks for your patience."
        );
        setPaymentLoading(false);
        dispatch({
          type: types.CONFIRM_WORLDLINE_PAYMENT_FAIL,
        });
      });
  };

export const sendInvoice = (order_data, get_pdfs) => async (dispatch) => {
  const formData = new FormData();
  const pdfs = await get_pdfs(order_data);
  formData.append("invoice", pdfs["invoice"], "invoice.pdf");
  formData.append(
    "paymentConfirmation",
    pdfs["paymentConfirmation"],
    "paymentConfirmation.pdf"
  );
  await axios
    .post(
      REQUEST_URL + `/api/carts/order/email/?order=${order_data.id}`,
      formData,
      getTokenIncludedConfig()
    )
    .then((response) => {
      if (response.status === 200) {
        SuccessToast("Your Invoice is sent to your registered email.");
      }
    })
    .catch(function (error) {
      ErrorToast(
        "Your order was sucessfull, but there is some issue in sending pdfs please contact site admin."
      );
    });
};

export const makePayment =
  (setPaymentLoading, userProfile, is_site_cash, updateOrderUrl) => async (dispatch) => {
    setPaymentLoading(true);
    dispatch({
      type: types.WORLDLINE_PAYMENT_REQUEST_START,
    });
    const body = JSON.stringify({
      ...userProfile,
    });
    const endpoint = is_site_cash ? "sitecash" : "checkout";
    axios
      .post(
        REQUEST_URL + `/api/carts/create/order/${endpoint}/`,
        body,
        getTokenIncludedConfig()
      )
      .then((response) => {
        if(is_site_cash){
          updateOrderUrl(response.data.id)
        }
        else{
          window.location = response.data.redirect_url;
        }
        setPaymentLoading(false);
        dispatch({
          type: types.CONFIRM_WORLDLINE_PAYMENT_SUCCESS,
        });
      })
      .catch(function (error) {
        setPaymentLoading(false);
        ErrorToast(error.response.data.error);
        dispatch({
          type: types.CONFIRM_WORLDLINE_PAYMENT_FAIL,
        });
      });
  };
