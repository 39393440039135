import React, { useEffect } from "react";
import Order from "./order/Order";
import "moment/locale/ro";
import ShoppingCart from "./cart/cart";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import CookieConsent from "react-cookie-consent";
import PrivateRoute from "../hocs/PrivateRoutes";
import Winners from "./pages/Winners/winners.jsx";
import Myaccount from "./myaccount/myaccount.jsx";
import { getCartData } from "../actions/cart/cart";
import FAQ from "./pages/Landing-page/FAQ/faq.jsx";
import SignUp from "./pages/Forms/SignUp/signup.jsx";
import SignIn from "./pages/Forms/SignIn/signin.jsx";
import Google from "./pages/Forms/Google/google.jsx";
import GiftShop from "./pages/Gift-Shop/gift-shop.jsx";
import { load_user } from "../actions/user/accountInfo";
import { Route, Switch, NavLink } from "react-router-dom";
import { checkAuthenticated } from "../actions/user/auth";
import Facebook from "./pages/Forms/Facebook/facebook.jsx";
import HowToPlay from "./pages/How-To-Play/how-to-play.jsx";
import LandingPage from "./pages/Landing-page/landing-Page.jsx";
import Competitions from "./pages/Competitions/competitions.jsx";
import Newsletter from "./pages/Forms/Newsletter/newsletter.jsx";
import Wallet from "./myaccount/linkComponents/Wallet/wallet.jsx";
import Affilated from "./pages/Landing-page/Affilated/affilated.jsx";
import Tickets from "./myaccount/linkComponents/MyTickets/mytickets";
import ResetPassword from "./pages/Forms/reset_password/reset_password.jsx";
import AccountActivate from "./pages/Forms/account/activate/account_activate";
import Transaction from "./myaccount/linkComponents/Transections/transections.jsx";
import PrivacyPolicy from "./pages/Landing-page/Privacy-Policy/privacy-policy.jsx";
import SingleCompetition from "./pages/Competitions/SingleCompetition/Ticket/ticket";
import AccountDetail from "./myaccount/linkComponents/AccountDetail/AccountDetail.jsx";
import PastTickets from "./myaccount/linkComponents/MyTickets/PostTickets/posttickets";
import TermsConditions from "./pages/Landing-page/Terms-Conditions/terms-conditions.jsx";
import ActiveTickets from "./myaccount/linkComponents/MyTickets/ActiveTickets/activetickets";
import ResetPasswordConfirm from "./pages/Forms/reset_password_confirm/reset_password_confirm";

const App = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(load_user());
    dispatch(getCartData());
    dispatch(checkAuthenticated());

    const interval = setInterval(() => {
      dispatch(getCartData());
    }, 60000);
    return () => clearInterval(interval);
  });

  return (
    <div>
      {/* <CookieConsent
        location="top"
        buttonText="Accept Cookies"
        style={{ background: "#2B373B" }}
        expires={730}
      >
        <p>
          We use cookies on this website,&nbsp;you can &nbsp;
          <NavLink
            style={{ textDecoration: "underline black" }}
            className="cookiesLink"
            to="/privacypolicy"
          >
            read about them here.
          </NavLink>
          &nbsp; To use the website as intended please ... &nbsp;
        </p>
      </CookieConsent> */}
      <ToastContainer position="top-center" autoClose={4000} closeOnClick={false} />
      <Switch>
        <Route path="/order" component={Order} />
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/compID" />
        <Route exact path="/FAQ" component={FAQ} />
        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
        <Route exact path="/terms" component={TermsConditions} />
        <Route exact path="/affilated" component={Affilated} />
        <Route exact path="/winners" component={Winners} />
        <Route exact path="/giftshop" component={GiftShop} />
        <Route exact path="/competitions" component={Competitions} />
        <Route exact path="/howtoplay" component={HowToPlay} />
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/activate" component={SignIn} />
        <Route exact path="/facebook" component={Facebook} />
        <Route exact path="/google" component={Google} />
        <Route exact path="/reset" component={ResetPassword} />
        <Route exact path="/newsletter" component={Newsletter} />
        <Route
          exact
          path="/password/reset/confirm/:uid/:token"
          component={ResetPasswordConfirm}
        />
        <Route
          exact
          path="/account/activate/:uid/:token"
          component={AccountActivate}
        />

        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/shoppingcart" component={ShoppingCart} />
        <Route
          exact
          path="/competitions/sub-section/:id"
          component={SingleCompetition}
        />
        <PrivateRoute exact path="/myaccount" component={Myaccount} />

        <Route
          exact
          path="/myaccount/acountdetail"
          render={() => {
            return (
              <Myaccount>
                <AccountDetail />
              </Myaccount>
            );
          }}
        />
        <Route
          exact
          path="/myaccount/tickets"
          render={() => {
            return (
              <Myaccount>
                <Tickets />
              </Myaccount>
            );
          }}
        />
        <Route
          exact
          path="/myaccount/tickets/activetickets"
          render={() => {
            return (
              <Myaccount>
                <Tickets>
                  <ActiveTickets />
                </Tickets>
              </Myaccount>
            );
          }}
        />

        <Route
          exact
          path="/myaccount/tickets/pasttickets"
          render={() => {
            return (
              <Myaccount>
                <Tickets>
                  <PastTickets />
                </Tickets>
              </Myaccount>
            );
          }}
        />
        <Route
          exact
          path="/myaccount/transaction"
          render={() => {
            return (
              <Myaccount>
                <Transaction />
              </Myaccount>
            );
          }}
        />

        <Route
          exact
          path="/myaccount/wallet"
          render={() => {
            return (
              <Myaccount>
                <Wallet />
              </Myaccount>
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default App;
