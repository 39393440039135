import React from "react";
import "./affilated.css";

import Navbar from "../Navbar/navbar.jsx";

import Footer from "../Footer/footer";

class Affilated extends React.Component {
  render() {
    return (
      <>
        <Navbar />
        <section
          class="affilated"
          id="aff-title"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h4>
            <span>Acord de Afiliere</span>
          </h4>
          <div>
            <p>
              <strong>
                A face bani cu Programul de Afiliere Paradise Competitions este
                ușor. Funcționează conform modelului de marketing afiliat „Plată
                pe vânzare”. Este simplu. Mai întâi vă înregistrați. Apoi
                aplicați pentru a deveni afiliatul nostru. După ce deveniți
                afiliat, partenerul nostru, promovați site-ul nostru și ne
                aduceți noi clienți. Câștigați comisioane pentru fiecare
                tranzacție pe care o fac clienții aduși de dvs., iar noii
                clienți primesc o reducere de 30% la primul lor coș de
                cumpărături.
              </strong>
            </p>

            <p>
              <strong>
                Acest Acord Programului de Afiliere a fost modificat ultima dată
                la 16/11/2023.
              </strong>
            </p>

            <p>
              <strong>
                <span>Acceptarea Termenilor</span>
              </strong>
            </p>

            <p>
              <span>
                Acest Acord este o parte integrală a Acordului dintre Client și
                Paradise Competitions și conține termenii și condițiile
                interacțiunii dintre Clientul înregistrat ca afiliat în
                Programul de Afiliere Paradise Competitions și Paradise
                Competitions. Scopul acestui Acord este de a{" "}
                <strong>
                  oferi o reducere la primul coș cumpărat de noul client și de a
                  permite plățile către Afiliat
                </strong>{" "}
                precum și de a stabili legătura HTML între site-ul Afiliatului
                și site-urile paradisecompetitions.ro, paradisecompetitions.com,
                și toate celelalte site-uri deținute sau operate de Paradise
                Competitions.
              </span>
            </p>

            <ol>
              <li>
                <strong>
                  <span style={{ color: "lightgray" }}>
                    1. Cine poate deveni Afiliat ?
                  </span>
                </strong>
              </li>
            </ol>

            <p>
              <span>
                Oricine poate ajunge la o audiență mare sau un public numeros
                este binevenit să devină afiliat al Paradise Competitions.&nbsp;
                Dacă crezi că oamenii care trec pe lângă fereastra ta, sau
                prietenii tăi, clienții tăi, cititorii și urmăritorii tăi online
                ar aprecia Paradise Competitions, ar trebui să te alături
                programului nostru de afiliere.
              </span>
            </p>

            <p>
              <span>
                Poți aplica trimițând de la adresa de e-mail înregistrată în
                contul tău un e-mail la{" "}
                <strong>support@paradisecompetitions.ro</strong>, în limba
                română, sau engleză, cu subiectul{" "}
                <strong>&ldquo;Afiliat Nou&rdquo;</strong> și menționând{" "}
                <strong>Audiența ta de Afiliat, campania ta</strong> și{" "}
                <strong>Codul Promoțional de Înregistrare</strong> ales de tine.
                De exemplu:
              </span>
            </p>

            <p>
              <span>
                Audiența de Afiliat: pietonii din jurul la Gara Centrală
                București, Campanie: staționare la intrare cu o publicitate cu
                cod promotional, Cod Promoțional: CENTRAL.
              </span>
            </p>

            <p>
              <span>
                Audiența de Afiliat: clienții din restaurantul meu PIZZA ROMINA,
                strada ..., orașul ..., Campanie: afișarea unei publicități cu
                cod promoțional în restaurantul meu, Cod Promoțional:
                PIZZAROMINA.
              </span>
            </p>

            <p>
              <span>
                Audiența de Afiliat: urmăritorii canalului meu Instagram
                &ldquo;123&rdquo;, Campanie: menționarea frecventă a
                &ldquo;codului promoțional&rdquo; și &ldquo;Link-ului&rdquo; în
                postări, Cod Promoțional: &ldquo;123&rdquo;
              </span>
            </p>

            <p>
              <span>
                Vom verifica disponibilitatea codului promoțional dorit și te
                vom notifica prin e-mail. În funcție de numărul de solicitări
                primite, acest proces poate dura câteva zile. Te vom anunța,
                astfel că nu este necesar să-ți retrimiți cererea.
              </span>
            </p>

            <ol start="2">
              <li>
                <strong>
                  <span style={{ color: "lightgray" }}>2. Definiții</span>
                </strong>
              </li>
            </ol>

            <p>
              <strong>
                <span>Afiliat </span>
              </strong>
              <span>
                înseamnă o persoană înregistrată în Companie ca Afiliat în
                Programul de Afiliere.
              </span>
            </p>

            <p>
              <strong>
                <span>Programul de Afiliere </span>
              </strong>
              <span>înseamnă Programul de Afiliere Paradise Competitions.</span>
            </p>

            <p>
              <strong>
                <span>Site-urile Afiliatului</span>
              </strong>
              <span>
                {" "}
                înseamnă site-urile web de pe internet operate de Afiliat, care
                pot sau nu să conțină Link-ul Afiliatului.
              </span>
            </p>

            <p>
              <strong>
                <span>Audiența de Afiliat</span>
              </strong>
              <span>
                {" "}
                înseamnă persoanele către care se adresează Afiliatul.
              </span>
            </p>

            <p>
              <strong>
                <span>Link-urile Afiliatului</span>
              </strong>
              <span>
                {" "}
                înseamnă link-ul personal al Afiliatului și/sau link-uri către
                Site-urile Paradise Competitions. Acest lucru poate lua forma
                unui Cod Promoțional de Înregistrare și/sau a unei legături
                HTML.
              </span>
            </p>

            <p>
              <strong>
                <span>Codul Promoțional de Înregistrare </span>
              </strong>
              <span>
                înseamnă Codul Promoțional, de exemplu, orice literă, număr sau
                combinație literă-număr, care poate fi utilizat de Audiența de
                Afiliat la Înregistrare și, când este utilizat, le oferă
                clienților săi o reducere de 30% la primul coș de cumpărături
                achiziționat.
              </span>
            </p>

            <p>
              <strong>
                <span>Lead </span>
              </strong>
              <span>
                înseamnă orice persoană din Audiența de Afiliat care s-a
                înregistrat pe Platformă și care este identificată în sistemele
                informatice ale Paradise Competitions ca o persoană care a
                accesat Platforma direct prin Link-ul Afiliatului pentru
                înregistrare. Afiliatul și Lead-ul rămân conectați atâta timp
                cât Lead-ul rămâne un Client Activ și în circumstanțele permise.
                Orice persoană care accesează Platforma prin Link-ul Afiliatului
                după ce acea persoană s-a înregistrat deja în Site-urile
                Paradise Competitions fără a utiliza Link-ul nu va fi
                considerată un Lead.
              </span>
            </p>

            <p>
              <strong>
                <span>Client Activ</span>
              </strong>
              <span>
                {" "}
                înseamnă persoana înregistrată sau Lead-ul care a efectuat cel
                puțin o tranzacție reușită pe contul lor personal în ultimele 12
                luni.
              </span>
            </p>

            <p>
              <strong>
                <span>Mărcile Paradise Competitions</span>
              </strong>
              <span>
                {" "}
                înseamnă toate mărcile comerciale, denumirile comerciale, numele
                de marcă și/sau numele de domeniu ale Paradise Competitions.
              </span>
            </p>

            <p>
              <strong>
                <span>Platformă </span>
              </strong>
              <span>
                înseamnă platforma de comerț electronic cu pagini web, site-uri
                web și aplicații deținute, întreținute sau operate de Paradise
                Competitions, care oferă utilizatorilor săi servicii, inclusiv
                activități de comerț electronic online, de exemplu, site-urile
                paradisecompetitions.ro, paradisecompetitions.com, și toate
                celelalte site-uri și pagini.
              </span>
            </p>

            <p>
              <strong>
                <span>Materialele Licențiate </span>
              </strong>
              <span>
                includ ideile unice ale Paradise Competitions, videoclipurile
                digitale fortune cookie, logourile, denumirile comerciale,
                mărcile comerciale, mărcile de serviciu și materialele de
                identificare similare.
              </span>
            </p>

            <p>
              <strong>
                <span>Legi și Regulamente Aplicabile </span>
              </strong>
              <span>
                se referă la Regulile pieței relevante și toate celelalte legi
                aplicabile și reglementările în vigoare de la un moment la altul
                în orice jurisdicție.
              </span>
            </p>

            <ol start="3">
              <li>
                <strong>
                  <span style={{ color: "lightgray" }}>
                    3. Reprezentări și Garanții
                  </span>
                </strong>
              </li>
            </ol>

            <p>
              <span>
                Affiliate-ul declară și garantează prin prezenta următoarele
                către Paradise Competitions:
              </span>
            </p>

            <p>
              <span>
                 Acordul nu va intra în conflict cu sau încălca nicio lege sau
                reglementare aplicabilă sau nicio înțelegere la care
                Affiliate-ul este supus;
              </span>
            </p>

            <p>
              <span>
                 Affiliate-ul este un adult cu vârsta de cel puțin 18 ani.
              </span>
            </p>

            <ol start="4">
              <li>
                <strong>
                  <span style={{ color: "lightgray" }}>
                    4. Beneficiile pentru Noii Clienți
                  </span>
                </strong>
              </li>
            </ol>

            <p>
              <span>
                Compania, prin intermediul Affiliate-ului său, oferă întregii
                audiențe a Affiliate-ului o reducere unică de 30% la prima
                tranzacție de succes pe Platformă atunci când aceștia intră
                folosind Link-ul.
              </span>
            </p>

            <ol start="5">
              <li>
                <strong>
                  <span style={{ color: "lightgray" }}>
                    5. Comisioanele și Plățile pentru Afiliați
                  </span>
                </strong>
              </li>
            </ol>

            <p>
              <span>
                5.1. Compania plătește remunerația pentru Afiliați conform
                următoarelor planuri de comisioane: &laquo;
              </span>
              Introducing Affiliate&raquo; and &laquo;PC-SC&raquo;.{" "}
              <span>Noii Afiliați intră în planul de comisioane &laquo;</span>
              Introducing Affiliate&raquo;. Acei Afiliați care au 100 de
              Lead-uri ca Cliți Activi sub ei intră automat în planul de
              comisioane &laquo;PC-SC&raquo;.
            </p>

            <p>
              <span>
                5.2. Sub planul de comisioane &laquo;Introducing
                Affiliate&raquo; Paradise Competitions plătește Afiliaților 2%
                din tranzacțiile Lead-urilor lor care sunt Cliți Activi.
              </span>
            </p>

            <p>
              <span>
                5.3. Sub planul &laquo;PC-SC&raquo; sau planul de comisioane
                Paradise Competitions Shopping Crowd, Paradise Competitions
                plătește Afiliaților 2% din tranzacțiile Lead-urilor lor care
                sunt Cliți Activi.
              </span>
            </p>

            <p>
              5.4. Odată intrat în planul &laquo;PC-SC&raquo;, Paradise
              Competitions vă permite să modificați Codul Promoțional de
              Înregistrare la cerere simplă.
            </p>

            <p>
              <span>
                5.5. Comisioanele sunt plătite Afiliaților zilnic, ca rezultat
                al tranzacțiilor Lead-urilor din ziua anterioară.
              </span>
            </p>

            <p>
              <span>
                5.6. Remunerația este acumulată în contul agentului în zona
                Afiliaților.
              </span>
            </p>

            <p>
              <span>
                5.7. Retragerile de comisioane către Afiliați sunt posibile după
                ce au fost recomandați 5 Lead-uri și pentru o sumă minimă de
                retragere de 100 RON.
              </span>
            </p>

            <p>
              <span>
                5.8. Retragerile se fac către orice cont ales de Afiliat.
              </span>
            </p>

            <ol start="6">
              <li>
                <strong>
                  <span style={{ color: "lightgray" }}>
                    6. Reguli Generale de Plată
                  </span>
                </strong>
              </li>
            </ol>

            <p>
              <span>
                6.1. Toate sumele, inclusiv sumele retrase și tranzacțiile, sunt
                stabilite în valuta afișată pe Platformă.
              </span>
            </p>

            <p>
              <span>
                6.2. Comisionul nu se plătește Afiliaților pentru Clenții atrași
                dacă acești Clienți sunt Afiliatul însuși sau persoanele
                afiliate ale Afiliatului. Persoanele afiliate aici înseamnă: (i)
                orice membri ai familiei Afiliatului în primul grad; (ii) orice
                persoană fizică sau parteneriat care controlează direct sau
                indirect, este controlată sau este sub controlul comun al
                Afiliatului.
              </span>
            </p>

            <p>
              <span>
                6.3. Toate tarifele vor fi plătite conform acordului prealabil
                dintre Afiliat și Paradise Competitions, iar Paradise
                Competitions își rezervă dreptul de a modifica și/sau a adăuga
                programul de comisioane în orice moment, cu efect imediat, prin
                notificarea Afiliatului.
              </span>
            </p>

            <p>
              <span>
                6.4. Comisioane suplimentare pot fi plătite Afiliaților de către
                Paradise Competitions la propria lor discreție, dupa caz.
              </span>
            </p>

            <p>
              <span>
                6.5. În afara zonei EURO, Paradise Competitions poate deduce
                orice cheltuieli asociate cu metoda de plată STRIPE. În ANEXA A
                este explicat modul în care funcționează aceste cheltuieli.
              </span>
            </p>

            <p>
              <span>
                6.6. Afiliatul trebuie să furnizeze informații precise cu
                privire la metoda de plată. Paradise Competitions nu poate fi
                trasă la răspundere pentru orice întârzieri ale taxelor datorate
                furnizării de detalii inexacte de plată.
              </span>
            </p>

            <p>
              <span>
                6.7. Comisioanele de plata includ toate taxele, tarifele și
                plățile obligatorii care pot fi aplicate și în măsura în care
                este necesar. Paradise Competitions poate deduce și/sau reține
                toate aceste plăți obligatorii care pot fi datorate.
              </span>
            </p>

            <p>
              <span>
                6.8. Paradise Competitions își rezervă dreptul, în termen de 180
                de zile de la efectuarea plăților de taxe către Afiliat, de a
                revizui toate taxele pentru posibile fraude sau încălcări ale
                termenilor acestui Acord. Paradise Competitions are dreptul să
                rețină orice taxe datorate Afiliatului până în momentul
                încheierii revizuirii.
              </span>
            </p>

            <ol start="7">
              <li>
                <strong>
                  <span style={{ color: "lightgray" }}>
                    7. Obligații și Responsabilități ale Afiliaților
                  </span>
                </strong>
              </li>
            </ol>

            <p>
              <span>
                În timpul derulării acestui Acord, Afiliații se angajează să
                depună toate eforturile pentru a promova activ și eficient
                Paradise Competitions, produsele și/sau serviciile sale și
                platforma sa în teritoriul specificat de Paradise Competitions
                prin intermediul site-urilor Afiliaților și/sau a materialelor
                promoționale de referință.
              </span>
            </p>

            <p>
              <span>
                7.1. Afiliații sunt de acord să utilizeze Brandurile Paradise
                Competitions numai în măsura permisă, adică într-un mod legal și
                în strictă conformitate cu toate legile și reglementările
                aplicabile, format(e), ghiduri, standarde și alte cerințe
                stabilite de Paradise Competitions. În special, Afiliații sunt
                de acord să nu participe la nicio activitate interzisă,
                incluzand, dar nelimitandu-se la, următoarele.
              </span>
            </p>

            <p>
              <span>
                7.2. Denigrarea Paradise Competitions, produselor Paradise
                Competitions și/sau serviciilor în orice fel. Orice denigrare a
                Paradise Competitions sau a produselor și serviciilor sale poate
                duce la suspendarea imediată a statutului de Afiliat, precum și
                la rezilierea acestui Acord, cu anularea oricăror taxe în
                așteptare.
              </span>
            </p>

            <p>
              <span>
                7.3. Paradise Competitions are o toleranță zero față de spam.
                Spam-ul este definit ca trimiterea unui mesaj electronic
                comercial, inclusiv text, sunet, voce sau imagine, pe care
                destinatarul nu a consimțit să-l primească, nu identifică
                expeditorul, și informațiile de contact ale expeditorului nu
                sunt conforme cu legile și reglementările privind protecția
                datelor, și nu are un mecanism de dezabonare.
              </span>
            </p>

            <p>
              <span>
                7.4. Afiliatul este de acord ca site-ul și/sau blogurile sale să
                nu copieze sau să semene în niciun fel cu aspectul și senzația
                site-ului Paradise Competitions, inclusiv, dar fără a se limita
                la culori și design. Afiliatul este, de asemenea, de acord ca
                platforma sa să nu conțină niciun conținut al Produsului sau
                serviciului sau orice Material care este proprietatea Paradise
                Competitions, cu excepția Materialelor obținute cu permisiunea
                prealabilă în scris a Paradise Competitions, cum ar fi
                materialele promoționale.
              </span>
            </p>

            <p>
              <span>
                7.5. Afiliatul nu va încerca acum sau in viitor, să înregistreze
                vreun Brand Paradise Competitions și/sau Materiale Licențiate
                și/sau nume de domeniu și/sau să conteste validitatea vreunui
                Brand Paradise Competitions sau să folosească vreun termen sau
                marcă nici macar asemănătoare cu oricare dintre Brandurile
                Paradise Competitions.
              </span>
            </p>

            <p>
              <span>
                7.6. Link-urile Afiliatului vor afișa în mod clar logo-ul
                oficial al Paradise Competitions în conformitate cu prevederile
                acestui Acord. Afiliatului nu i se permite să modifice
                logo-urile și/sau bannerele și/sau imaginile noastre și/sau să
                le utilizeze în alte scopuri decât cele permise prin prezentul
                acord. Paradise Competitions își rezervă dreptul de a modifica
                în orice moment logo-ul său oficial și/sau Brandul Paradise
                Competitions și/sau orice alte materiale de marketing.
              </span>
            </p>

            <p>
              <span>
                7.7. Afiliatul nu trebuie sa se: (a) implice în niciun fel de
                broadcast sau telemarketing și/sau orice altă metodă de
                marketing offline referitoare la Platformă și Paradise
                Competitions (b) foloseasca tehnici Malware și/sau Spyware
                și/sau distribuție Pier to Pier (P2P) și/sau rețele Paid to
                Click (PTC) și/sau pagini de intrare (doorway deschise pages)
                deschise pe site-ul Paradise Competitions într-un cadru și/sau
                utilizează orice alte metode agresive de publicitate sau
                marketing în oricare dintre activitățile sale referitoare la
                Platforma Paradise Competitions Platform, produsele și/sau
                serviciile.
              </span>
            </p>

            <p>
              <span>
                7.8. Afiliatul nu poate promova produsele și/sau serviciile
                Paradise Competitions pe nicio platformă care promovează
                materiale explicit sexuale, violență, materiale piratate,
                discriminare pe bază de rasă, sex, gen, religie, origine
                națională, dizabilități fizice, probleme politice sau
                controversate sau activități ilegale.
              </span>
            </p>

            <p>
              <span>
                7.9. Afiliatul nu poate utiliza Materialele și Materialele
                Licențiate, inclusiv marca de serviciu/trademark Paradise
                Competitions&reg; (scrisă în orice mod posibil) în niciun fel
                sau manieră, inclusiv în reclamele URL-ului de afișare și/sau în
                URL-urile de destinație, decât cu aprobarea scrisă a Paradise
                Competitions și cu condiția ca astfel de conținut să fie
                utilizat exclusiv în scopul promovării Produsului și/sau
                serviciilor Paradise Competitions în conformitate cu termenii
                acestui Acord. Prin urmare, Afiliatul nu poate modifica, sau
                schimba niciun Material Licențiat în niciun fel.
              </span>
            </p>

            <p>
              <span>
                7.10. Afiliatul va fi responsabil exclusiv pentru operațiunile
                tehnice ale campaniei sale sau a site-ului web și pentru
                acuratețea și adecvarea materialelor postate acolo.
              </span>
            </p>

            <p>
              <span>
                7.11. Afiliatul nu poate distorsiona produsele și/sau serviciile
                Paradise Competitions; și va fi responsabil și răspunzător
                pentru obținerea și menținerea, precum și pentru conformitatea
                cu, toate legile și reglementările aplicabile necesare pentru
                îndeplinirea obligațiilor Afiliatului în conformitate cu acest
                Acord, inclusiv legile aplicabile privind comerțul și valorile
                mobiliare. Paradise Competitions nu va fi în niciun caz
                răspunzător pentru Afiliatul sau, Paradise Competitions nu va
                despăgubi împotriva oricăror pretenții afirmate împotriva
                Paradise Competitions din cauza activităților de publicitate,
                marketing și promovare ale Afiliatului și/sau ale partenerului
                său de afaceri.
              </span>
            </p>

            <p>
              <span>
                7.12. Afiliatul nu se va angaja în nicio activitate care ar
                putea fi percepută ca furnizând mai mult decât informații
                generale de marketing sau activitati promoționale despre
                Paradise Competitions și produsele și/sau serviciile sale, în
                conformitate cu acest Acord. În special, Afiliatul nu va furniza
                niciun serviciu de investiții și/sau servicii conexe în
                conformitate cu acest Acord și va acționa întotdeauna în
                conformitate cu legile și reglementările aplicabile.
              </span>
            </p>

            <p>
              <span>
                7.13. Prin prezenta, Afiliatul eliberează Paradise Competitions
                de orice responsabilitate privind acuratețea sau fiabilitatea
                informațiilor (inclusiv tarife, fie furnizate de Paradise
                Competitions sau de oricare altă parte) prezentate clienților
                prin intermediul Platformei. Afiliatul recunoaște în plus că
                este conștient că informațiile prezentate clienților prin
                intermediul Platformei nu implică o recomandare de a încheia sau
                de a se abține de la încheierea unei tranzacții specifice.
              </span>
            </p>

            <p>
              <span>
                7.14. Afiliatul este de acord să despăgubească și să mențină
                Paradise Competitions nevinovată față de toate revendicările,
                daunele și cheltuielile (inclusiv, fără limitare, onorariile
                avocaților și cheltuielile de expertiză) legate de dezvoltarea,
                funcționarea, întreținerea și conținutul site-urilor afiliate
                sau a oricăror materiale, produse sau servicii, legate de
                acestea.
              </span>
            </p>

            <ol start="8">
              <li>
                <strong>
                  <span style={{ color: "lightgray" }}>
                    8. Termen și Încetare
                  </span>
                </strong>
              </li>
            </ol>

            <p>
              <span>
                8.1. Termenii acestui Acord încep odată cu acceptarea
                Afiliatului în Programul de Afiliere Paradise Competitions și se
                încheie la rezilierea de către oricare dintre părți.
              </span>
            </p>

            <p>
              <span>
                8.2. Oricare dintre părți poate rezilia acest Acord în orice
                moment, fără niciun motiv. La rezilierea acestui Acord,
                Afiliatul va înceta imediat utilizarea tuturor Materialelor și
                Materialelor Licențiate.
              </span>
            </p>

            <p>
              <span>
                8.3. Paradise Competitions poate rezilia acest Acord în orice
                moment din motive de conveniență dacă se constată că Afiliatul
                este direct sau indirect implicat în orice activitate
                frauduloasă, înșelătoare, manipulativă sau alte activități
                ilegale, inclusiv spam, publicitate falsă, utilizarea datelor
                furate ale cardului bancar, scheme de fraudă legate de bonusuri,
                promoții și oferte speciale ale Companiei, atragerea clienților
                cu o ofertă de a împărți comisionul, cu excepția cazului în care
                acest lucru a fost convenit anterior cu Paradise Competitions și
                utilizarea de către Client a comisionului pentru activități
                frauduloase, precum și orice alte activități definite de
                Paradise Competitions ca fiind frauduloase sau care încalcă
                Acordul companiei. În acest caz, Afiliatul nu are dreptul la
                primirea vreunui comision realizat prin mijloace frauduloase.
                Paradise Competitions își rezervă dreptul de a considera
                comisionul acumulat drept compensație pentru daunele cauzate de
                companie ca urmare a activităților ilegale sau frauduloase ale
                Afiliatului.
              </span>
            </p>

            <p>
              <span>
                8.4. După rezilierea acestui Acord și plata Afiliatului a
                tuturor comisioanelor datorate la momentul rezilierii, Paradise
                Competitions nu are nicio obligație de a face alte plăți de
                comisioane către Afiliat.
              </span>
            </p>

            <p>
              <span>
                8.5. În cazul rezilierii de către Paradise Competitions a
                acestui Acord din cauza încălcării de către Afiliat a acestui
                Acord sau a oricărei politici a Paradise Competitions, Afiliatul
                nu va avea dreptul să primească niciun comision care i-a fost
                acumulat în beneficiu.
              </span>
            </p>

            <p>
              <span>
                8.6. Aprobarea ca Afiliat și devenirea parte a echipei de
                marketing a Paradise Competitions oferă acces la câștiguri.{" "}
                <strong>
                  În schimbul acestor câștiguri, Paradise Competitions se
                  așteaptă ca Afiliatul să aducă noi Clienți.{" "}
                </strong>
                În cazul în care există o perioadă de 1 an în care activitățile
                Afiliatului nu au generat niciun Lead nou sub el/ea, Paradise
                Competitions are dreptul de a rezilia acest Acord, de a închide
                contul de agent al Afiliatului, iar Afiliatul nu va mai avea
                dreptul să primească niciun comision nou de la foștii săi Leads.
              </span>
            </p>

            <ol start="9">
              <li>
                <strong>
                  <span style={{ color: "lightgray" }}>
                    9. Proprietate intelectuală / Confidențialitate
                  </span>
                </strong>
              </li>
            </ol>

            <p>
              <span>
                9.1. Între Paradise Competitions și Afiliat, Platforma, Mărcile
                Paradise Competitions, materialele creative ale Paradise
                Competitions, toate informațiile demografice și alte informații
                referitoare la orice Client (inclusiv Datele Clientului), precum
                și toate software-urile, documentațiile, hardware-urile,
                echipamentele, dispozitivele, șabloanele, uneltele, documentele,
                procesele, metodologiile, know-how-ul, site-urile web și orice
                altă proprietate intelectuală sau alt bun utilizat de sau în
                numele Paradise Competitions sau în legătură cu Platforma,
                împreună cu toate drepturile de autor, mărcile comerciale,
                brevetele, secretele comerciale, ideile unice (adică
                videoclipurile cu mesaje din furculiță) și orice alte drepturi
                de proprietate inherent în acestea și aferente acestora
                (colectiv &ldquo;Proprietatea Paradise Competitions&ldquo;),
                sunt și vor rămâne proprietatea exclusivă a Paradise
                Competitions, care își va păstra toate drepturile, titlurile și
                interesele asupra Proprietății Paradise Competitions și asupra
                tuturor Drepturilor de Proprietate Intelectuală derivate de
                acolo (inclusiv toate derivațiile și modificările aduse
                acestora).
              </span>
            </p>

            <p>
              <span>
                9.2. Părțile recunosc și sunt de acord ca, din când în când,
                înainte, în timpul și după Termenul prezentului Acord, oricare
                dintre părți poate avea acces la sau să devină familiarizată cu{" "}
                <strong>Informațiile Confidențiale</strong> ale celeilalte
                părți. Atât Afiliatul, cât și Paradise Competitions vor depune
                cele mai bune eforturi pentru a proteja Informațiile
                Confidențiale ale celuilalt de divulgare necorespunzătoare și nu
                vor utiliza sau dezvălui direct sau indirect, în timpul sau după
                Termenul prezentului Acord sau ulterior, orice astfel de
                Informații Confidențiale unei persoane, firme sau corporații
                pentru orice motiv sau scop, niciuna dintre părți, pentru sine
                sau în orice capacitate de reprezentare sau altă capacitate, nu
                va utiliza astfel de Informații Confidențiale în niciun fel în
                contul său sau în contul altora, cu excepția îndeplinirii
                obligațiilor sale în temeiul prezentului Acord.
              </span>
            </p>

            <p>
              <span>
                9.3. Se clarifică în continuare că Datele Clientului vor fi
                considerate ca informații și proprietate exclusivă și
                confidențială a Paradise Competitions și vor fi considerate ca
                Informații Confidențiale ale Paradise Competitions.
              </span>
            </p>

            <ol start="10">
              <li>
                <strong>
                  <span style={{ color: "lightgray" }}>
                    10. Repararea prejudiciilor
                  </span>
                </strong>
              </li>
            </ol>

            <p>
              <span>
                Afiliatul este de acord să despăgubească, să apere și să
                exonereze de orice răspundere Paradise Competitions, acționarii
                săi, ofițerii, directorii, angajații, agenții, afiliații,
                succesori și cesionari, împotriva oricăror pretenții,
                răspunderi, daune sau cheltuieli (inclusiv onorarii de avocat și
                costuri) de orice natură, suferite sau suferite de aceștia
                (colectiv &ldquo;Pierderi&rdquo;), în măsura în care astfel de
                Pierderi (sau acțiuni în legătură cu acestea) rezultă din sau se
                bazează pe (i) încălcarea acestui Acord de către Afiliat sau a
                oricărei reprezentări sau garanții făcute de Afiliat în
                prezentul document; sau (ii) orice acțiune sau omisiune în
                numele Afiliatului.
              </span>
            </p>

            <ol start="11">
              <li>
                <strong>
                  <span style={{ color: "lightgray" }}>
                    11. Rezervări și Răspundere
                  </span>
                </strong>
              </li>
            </ol>

            <p>
              <span>
                11.1. Paradise Competitions își rezervă dreptul de a întreprinde
                acțiuni legale împotriva Afiliatului în cazul în care Afiliatul
                încearcă să manipuleze Paradise Competitions și/sau Platforma
                și/sau să abuzeze de Programul de Afiliere. Fără a aduce
                atingere prevederilor anterioare, Paradise Competitions își
                rezervă dreptul de a reține și/sau deduce de la orice plată
                datorată Afiliatului în cazul unei astfel de manipulări și/sau
                abuzuri și poate, de asemenea, să rezilieze prezentul Acord cu
                efect imediat.
              </span>
            </p>

            <p>
              <span>
                11.2. Paradise Competitions nu va fi responsabil pentru daune
                indirecte, speciale sau de consecința, sau pentru orice pierdere
                de venituri, profituri sau costuri de date care apar în legătură
                cu acest Acord sau programul, chiar dacă Paradise Competitions a
                fost informată cu privire la posibilitatea unor astfel de daune.
                Răspunderea globală a Paradise Competitions în legătură cu acest
                Acord și Programul de Afiliere nu va depăși totalul tarifelor
                plătite sau plătibile Afiliatului în conformitate cu acest Acord
                în decursul celor trei luni imediat anterioare evenimentului
                care îi acordă Afiliatului dreptul la despăgubiri, iar în cazul
                mai multor evenimente (indiferent dacă sunt sau nu legate sau
                similare) totalul tarifelor plătite sau plătibile dumneavoastră
                în temeiul prezentului Acord în decursul celor trei luni imediat
                anterioare primului eveniment care îi acordă Afiliatului dreptul
                la despăgubiri. Afiliatul este de acord să respecte toate
                limitările de răspundere stabilite în prezentul document.
              </span>
            </p>

            <ol start="12">
              <li>
                <strong>
                  <span style={{ color: "lightgray" }}>
                    12. Excludere de Garanție
                  </span>
                </strong>
              </li>
            </ol>

            <p>
              <span>
                Cu excepția a ceea ce este expres menționat mai sus și în măsura
                maximă permisă de legea aplicabilă, Paradise Competitions nu
                oferă nicio garanție de niciun fel, expresă, implicită sau
                legală, referitoare la Platformă, iar toate astfel de garanții,
                inclusiv, fără limitare, garanțiile implicite de comercializare
                și/sau adecvare pentru un anumit scop, sunt expres excluse de
                către Paradise Competitions. Afiliatul recunoaște că Paradise
                Competitions nu a garantat și nu a reprezentat că Platforma va
                fi neîntreruptă, fără erori, fără întârzieri sau fără
                compromiterea sistemelor și caracteristicilor sale de
                securitate. Nu se fac reprezentări sau garanții de niciun fel de
                către Paradise Competitions în ceea ce privește serviciile
                furnizate de orice operator de telefonie fără fir sau
                distribuitor autorizat.
              </span>
            </p>

            <ol start="13">
              <li>
                <strong>
                  <span style={{ color: "lightgray" }}>13. Diverse</span>
                </strong>
              </li>
            </ol>

            <p>
              <span>
                13.1. Prevederile cuprinse în acest Acord constituie întregul
                Acord între părți și nicio declarație sau stimulent cu privire
                la un astfel de obiect care nu este cuprins în acest Acord nu va
                fi valid sau obligatoriu între părți.
              </span>
            </p>

            <p>
              <span>
                13.2. Paradise Competitions poate modifica oricare dintre
                termenii și condițiile cuprinse în acest Acord, în orice moment,
                la propria sa discreție, fără furnizarea de notificări.
                Modificările pot include, dar nu se limitează la, schimbări în
                domeniul taxelor și regulilor Programului. Dacă orice modificare
                nu este acceptabilă pentru Afiliat, singurul recurs este de a
                rezilia prezentul Acord. Participarea continuă la Program după
                orice modificare va constitui acceptarea obligatorie a
                schimbării.
              </span>
            </p>

            <p>
              <span>
                13.3. În caz de orice discrepanță între sensurile oricăror
                versiuni traduse ale acestui Acord sau oricarei alte comunicări
                care fac parte din acest Acord în orice altă limbă, sensul
                versiunii în limba engleză va prevala.
              </span>
            </p>

            <p>
              <span>
                13.4. Niciun eșec al oricăreia dintre părți de a-și exercita
                drepturile în temeiul acestui Acord nu va fi considerat o
                renunțare la astfel de drepturi. Niciun termen sau prevedere
                aici nu va fi considerat renunțat sau orice încălcare scuzată,
                decât în măsura în care o astfel de renunțare sau consimțământ
                va fi făcută în scris și semnată de către părtea pretinsă a fi
                renunțat sau consimțit. Nicio renunțare a oricărei încălcări a
                acestui Acord nu va fi considerată a constitui o renunțare a
                oricărei alte încălcări sau viitoare încălcări.
              </span>
            </p>

            <p>
              <span>
                13.5. Dacă vreo prevedere a acestui Acord este considerată
                invalidă sau inaplicabilă, prevederile rămase ale acestui Acord
                nu vor fi afectate de aceasta și vor fi obligatorii pentru
                părțile semnatare, și vor fi aplicabile, ca și cum prevederea
                invalidă sau inaplicabilă nu ar fi inclusă aici.
              </span>
            </p>

            <p>
              <span>
                13.6. Nici acest Acord, nici drepturile acordate aici, niciun
                interes în cadrul acestora nu pot fi vândute, atribuite,
                transferate, delegate, subcontractate sau în alt fel transferate
                sau împovărate de către Afiliat în orice moment, fără
                consimțământul prealabil în scris al Paradise Competitions, și
                orice încercare de a face acest lucru va fi considerată nulă și
                neavenită. Paradise Competitions va avea dreptul să își atribuie
                drepturile și/sau obligațiile în temeiul acestui Acord oricărei
                Corporații Afiliate ale Paradise Competitions. Acest Acord va fi
                obligatoriu pentru părți și va conferi beneficii acestora și
                succesorilor și asociaților lor permisi în mod expres.
              </span>
            </p>

            <p>
              <span>
                13.7. Paradise Competitions și Afiliatul sunt contractanți
                independenți și nimic în acest Acord nu trebuie interpretat ca
                creând vreo asociere, întreprindere comună, agenție, franciză,
                reprezentare comercială sau relație de angajare între părți.
              </span>
            </p>

            <p>
              <span>
                13.8. Acest Acord va fi reglementat de legea stabilită în
                Termenii și Condițiile Generale.
              </span>
            </p>

            <p>
              <strong>
                <span>
                  ANEXA &laquo;A&raquo; LA ACORDUL PROGRAMULUI DE AFILIAT
                  PARADISE COMPETITIONS
                </span>
              </strong>
            </p>
            <div className="head">
              <p className="head-p">
                <span className="title-p">Commisiane</span>
                <span> Nivel 1</span>
                <span>Nivel 2 </span>
                <span>Nivel 3</span>
                <span>Nivel 4</span>
              </p>
              <p className="body-p">
                <span className="title-p1">1/ Costuri de manipulare</span>
                <span>0RON</span>
                <span>(1.2)</span>
                <span>(1.3)</span>
                <span>(1.4)</span>
              </p>
              <p className="body-p">
                <span className="title-p">2/ Taxă</span>
                <span>0%</span>
                <span>(2.2)</span>
                <span>(2.3)</span>
                <span>(2.4)</span>
              </p>
              <p className="body-p">
                <span className="title-p">3/ Costuri de tranzacție</span>
                <span>0%</span>
                <span>(3.2)</span>
                <span>(3.3)</span>
                <span>(3.4)</span>
              </p>
            </div>

            <p>
              <strong>
                <span>Nivel 1: </span>
              </strong>
              <span>
                România + Zona Euro (Austria, Belgia, Croația, Cipru, Estonia,
                Finlanda, Franța, Germania, Grecia, Irlanda, Italia, Letonia,
                Lituania, Luxemburg, Malta, Țările de Jos, Portugalia, Slovacia,
                Slovenia, Spania, Andorra, Monaco, San Marino, Vatican).
              </span>
            </p>

            <p>
              <strong>
                <span>Nivel 2: </span>
              </strong>
              <span>
                Elveția, Liechtenstein, Regatul Unit, Danemarca, Suedia,
                Norvegia, Australia, Noua Zeelandă, Japonia, Groenlanda,
                Gibraltar, Islanda, Ungaria, Polonia, Bulgaria, Croația,
                Republica Cehă.
              </span>
            </p>

            <p>
              <strong>
                <span>Nivel 3: </span>
              </strong>
              <span>
                Brunei, Hong Kong, Macao, Taiwan, Kuweit, Arabia Saudită, Oman,
                Emiratele Arabe Unite, Israel, Bahrain, Qatar, Singapore, Coreea
                de Sud, Insulele Feroe, Kazahstan, Rusia, Mexic, Argentina,
                Brazilia, Chile, Malaysia, China PR, Turcia, Guinea Ecuatorială,
                Kenya, Aruba, Armenia, Belarus, Ucraina, Serbia, Muntenegru,
                Panama, Uruguay, Antilele Olandeze, Azerbaidjan, Georgia,
                Bahamas, Antigua și Barbuda, Trinidad și Tobago, Saint Kitts și
                Nevis.
              </span>
            </p>

            <p>
              <strong>
                <span>Nivel 4: </span>
              </strong>
              <span>
                Egipt, Africa de Sud, Peru, Columbia, Venezuela, Indonezia,
                Filipine, Thailanda, Vietnam, India, Pakistan, Sri Lanka,
                Myanmar, Iran, Maroc, Algeria, Tunisia, Gambia, Senegal,
                Mauritania, Mali, Guinea, Coasta de Fildeș, Burkina Faso, Niger,
                Togo, Benin, Mauritius, Liberia, Sierra Leone, Ghana, Nigeria,
                Ciad, Republica Centrafricană, Camerun, Capul Verde, Sao Tome și
                Principe, Gabon, Republica Congo, Republica Democrată Congo,
                Angola, Guinea-Bissau, Teritoriul Britanic din Oceanul Indian,
                Seychelles, Rwanda, Etiopia, Djibouti, Tanzania, Uganda,
                Burundi, Mozambic, Zambia, Madagascar, Mayotte, Reunion,
                Zimbabwe, Namibia, Malawi, Lesotho, Botswana, Swaziland, Comore,
                Sfânta Elena, Albania, Moldova, Kosovo, Bosnia și Herțegovina,
                Macedonia, Insulele Falkland, Belize, Guatemala, El Salvador,
                Honduras, Nicaragua, Costa Rica, Saint Pierre și Miquelon,
                Haiti, Saint Barthelemy, Saint Martin, Bolivia, Guyana, Ecuador,
                Paraguay, Surinam, Curacao, Timorul de Est, Antarctica, Nauru,
                Papua Noua Guinee, Tonga, Insulele Solomon, Vanuatu, Fiji,
                Palau, Wallis și Futuna, Insulele Cook, Niue, Samoa, Kiribati,
                Noua Caledonie, Tuvalu, Polinezia Franceză, Tokelau, Micronezia,
                Insulele Marshall, Cambodgia, Laos, Bangladesh, Maldive, Liban,
                Iordania, Yemen, Palestina, Bhutan, Mongolia, Nepal,
                Tadjikistan, Turkmenistan, Kârgâzstan, Uzbekistan, Barbados,
                Anguilla, Insulele Virgine Britanice, Insulele Cayman, Bermuda,
                Grenada, Insulele Turks și Caicos, Montserrat, Samoa Americană,
                Sint Maarten, Saint Lucia, Dominica, Saint Vincent și Grenadine,
                Jamaica, Republica Dominicană.
              </span>
            </p>

            <p>&nbsp;</p>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default Affilated;
